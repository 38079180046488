var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-update-third-partner","cancel-variant":"outline-secondary","ok-title":_vm.$t('Edit'),"cancel-title":_vm.$t('Close'),"ok-variant":"success","centered":"","modal-class":"modal-success","title":_vm.$t('Update A Third Partner'),"size":"lg"},on:{"ok":_vm.confirmAdd,"hidden":function($event){return _vm.resetModal()}}},[_c('validation-observer',{ref:"updateThirdPartnerModalRef"},[_c('b-form',{staticClass:"auth-reset-password-form mt-2",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Name")))]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","rows":"4","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.newBalanceType.name),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "name", $$v)},expression:"newBalanceType.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Code")))]),_c('validation-provider',{attrs:{"name":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code","rows":"4","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.newBalanceType.code),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "code", $$v)},expression:"newBalanceType.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"balance_type_id"}},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Balance type")))]),_c('validation-provider',{attrs:{"name":"balance_type_id","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listBalanceType,"reduce":function (val) { return val.id; },"clearable":false,"placeholder":"Select type","label":"name","input-id":"type"},model:{value:(_vm.newBalanceType.balance_type_id),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "balance_type_id", $$v)},expression:"newBalanceType.balance_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"type"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Type")))]),_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeOptions,"reduce":function (val) { return val.value; },"clearable":false,"placeholder":"Select type","label":"label","input-id":"type"},model:{value:(_vm.newBalanceType.type),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "type", $$v)},expression:"newBalanceType.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"game_category_id"}},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Game category")))]),_c('validation-provider',{attrs:{"name":"game_category_id","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listGameCategory,"reduce":function (val) { return val.id; },"clearable":false,"placeholder":"Select type","label":"name","input-id":"type"},model:{value:(_vm.newBalanceType.game_category_id),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "game_category_id", $$v)},expression:"newBalanceType.game_category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"status"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Status")))]),_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"clearable":false,"placeholder":"Select type","label":"text","input-id":"type"},model:{value:(_vm.newBalanceType.status),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "status", $$v)},expression:"newBalanceType.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"logo"}},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Logo"))+" "),_c('span',{staticClass:"text-secondary"},[_vm._v("(Không > 10Mb)")])]),_c('validation-provider',{attrs:{"name":"logo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"input-id":"logo","accept":"image/jpeg,image/png,image/gif,image/svg,image/jpg"},model:{value:(_vm.newBalanceType.logo),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "logo", $$v)},expression:"newBalanceType.logo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"is_new"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Is new")))]),_c('validation-provider',{attrs:{"name":"is_new","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.yesNoOptions,"reduce":function (val) { return val.value; },"clearable":false,"placeholder":"Select type","label":"text","input-id":"is_new"},model:{value:(_vm.newBalanceType.is_new),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "is_new", $$v)},expression:"newBalanceType.is_new"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"description"}},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Start maintenance")))]),_c('validation-provider',{attrs:{"name":"description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"startMaintenance","config":_vm.formatFromDate,"placeholder":"YYYY-MM-DD hh:mm:ss"},model:{value:(_vm.newBalanceType.start_maintenance),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "start_maintenance", $$v)},expression:"newBalanceType.start_maintenance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"order"}},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("End maintenance")))]),_c('validation-provider',{attrs:{"name":"order","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"startMaintenance","config":_vm.formatFromDate,"placeholder":"YYYY-MM-DD hh:mm:ss"},model:{value:(_vm.newBalanceType.end_maintenance),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "end_maintenance", $$v)},expression:"newBalanceType.end_maintenance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }