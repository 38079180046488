<template>
  <div>
    <b-modal id="modal-update-third-partner" cancel-variant="outline-secondary" :ok-title="$t('Edit')" :cancel-title="$t('Close')"
      ok-variant="success" centered modal-class="modal-success" :title="$t('Update A Third Partner')" @ok="confirmAdd"
      @hidden="resetModal()" size="lg">
      <!-- form -->
      <validation-observer ref="updateThirdPartnerModalRef">
        <b-form method="POST" class="auth-reset-password-form mt-2" @submit.prevent="validationForm">
          <b-row>
            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="name">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Name") }}</label>
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input id="name" v-model="newBalanceType.name" rows="4"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="code">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Code") }}</label>
                <validation-provider #default="{ errors }" name="code" rules="required">
                  <b-form-input id="code" v-model="newBalanceType.code" rows="4"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="balance_type_id">
                <label class="mb-1"> {{ $t("Balance type") }}</label>
                <validation-provider #default="{ errors }" name="balance_type_id" rules="">
                  <v-select
                    v-model="newBalanceType.balance_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listBalanceType"
                    :reduce="(val) => val.id"
                    :clearable="false"
                    placeholder="Select type"
                    label="name"
                    input-id="type"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="type">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Type") }}</label>
                <validation-provider #default="{ errors }" name="type" rules="required">
                  <v-select
                    v-model="newBalanceType.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    placeholder="Select type"
                    label="label"
                    input-id="type"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="game_category_id">
                <label class="mb-1"> {{ $t("Game category") }}</label>
                <validation-provider #default="{ errors }" name="game_category_id" rules="">
                  <v-select
                    v-model="newBalanceType.game_category_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listGameCategory"
                    :reduce="(val) => val.id"
                    :clearable="false"
                    placeholder="Select type"
                    label="name"
                    input-id="type"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="status">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Status") }}</label>
                <validation-provider #default="{ errors }" name="status" rules="required">
                  <v-select
                    v-model="newBalanceType.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    placeholder="Select type"
                    label="text"
                    input-id="type"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="logo">
                <label class="mb-1"> {{ $t("Logo") }} <span class="text-secondary">(Không > 10Mb)</span></label>
                <validation-provider #default="{ errors }" name="logo">
                  <b-form-file
                    v-model="newBalanceType.logo"
                    input-id="logo"
                    accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="is_new">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Is new") }}</label>
                <validation-provider #default="{ errors }" name="is_new" rules="required">
                  <v-select
                    v-model="newBalanceType.is_new"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="yesNoOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    placeholder="Select type"
                    label="text"
                    input-id="is_new"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="description">
                <label class="mb-1"> {{ $t("Start maintenance") }}</label>
                <validation-provider #default="{ errors }" name="description" rules="">
                  <flat-pickr
                    id="startMaintenance"
                    :config="formatFromDate"
                    v-model="newBalanceType.start_maintenance"
                    class="form-control"
                    placeholder="YYYY-MM-DD hh:mm:ss"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="order">
                <label class="mb-1"> {{ $t("End maintenance") }}</label>
                <validation-provider #default="{ errors }" name="order" rules="">
                  <flat-pickr
                    id="startMaintenance"
                    :config="formatFromDate"
                    v-model="newBalanceType.end_maintenance"
                    class="form-control"
                    placeholder="YYYY-MM-DD hh:mm:ss"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    thirdPartnerDetail: {
      type: Object,
      default: null,
    },
    listBalanceType: {
      type: Array,
      default: null,
    },
    listGameCategory: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      newBalanceType: {
        name: '',
        code: '',
        balance_type_id: '',
        type: '',
        game_category_id: '',
        status: '',
        is_new: '',
        start_maintenance: '',
        end_maintenance: '',
        logo: '',
      },
    };
  },
  watch: {
    thirdPartnerDetail: {
      handler(newVal) {
        if (newVal) {
          this.newBalanceType = {
            id: newVal.id,
            name: newVal.name,
            code: newVal.code,
            balance_type_id: newVal.balance_type_id,
            type: newVal.type,
            game_category_id: newVal.game_category_id,
            status: newVal.status,
            is_new: newVal.is_new,
            start_maintenance: newVal.start_maintenance,
            end_maintenance: newVal.end_maintenance,
            logo: newVal.logo,
          };
        }
      },
      deep: true,
    },
  },
  methods: {
    validationForm() {
      this.$refs.updateThirdPartnerModalRef.validate().then((success) => {
        if (success) {
          this.updateParam(this.newBalanceType);
        }
      });
    },
    async updateParam(newBalanceType) {
      await this.$store
        .dispatch("setting/updateThirdPartner", newBalanceType)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code === "00") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$bvModal.hide("modal-update-third-partner");
                  this.resetModal();
                }, "500");
              });
              this.$emit("refetch-data");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
    },
    resetModal() {
      this.newBalanceType = {
        name: '',
        code: '',
        balance_type_id: '',
        type: '',
        game_category_id: '',
        status: '',
        is_new: '',
        start_maintenance: '',
        end_maintenance: '',
        logo: '',
      };
      this.$emit("update:thirdPartnerDetail", null);
    },
    confirmAdd(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    const typeOptions = [
      { label: "Payment", value: '1' },
      { label: "Game", value: '2' },
    ];
    const statusOptions = [
      { text: "Active", value: '1' },
      { text: "Inactive", value: '2' },
    ];
    const yesNoOptions = [
      { text: "Có", value: 1 },
      { text: "Không", value: 2 },
    ];
    const formatFromDate = {
      enableTime: true,
      dateFormat: "Y-m-d H:i:S",
    };
    return {typeOptions, statusOptions, yesNoOptions, formatFromDate};
  },
};
</script>