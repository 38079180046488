<template>
    <div>
        <div class="p-1">
            <b-row>
                <b-col v-if="$can('create', 'thirdpartner')" class="col d-flex justify-content-end">
                    <b-button variant="primary" @click="addNewTitle">
                        <feather-icon icon="PlusIcon" size="16" /> {{ $t("New") }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <b-card no-body>
            <b-table ref="refParamListTable" class="position-relative table-white-space mb-0 max-height-table" sticky-header
                head-variant="light" :items="dataParamList" responsive :fields="tableColumns" primary-key="user_id"
                :sort-by.sync="sortBy" show-empty empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">

                <template #cell(status)="data">
                    <div :class="`text-capitalize align-items-center d-flex`">
                        <span :class="`dot bg-${resolveStatus(data.item.status).variant}`" />{{
                            resolveStatus(data.item.status).label
                        }}
                    </div>
                </template>

                <template #cell(logo)="data">
                    <b-link
                        :href="baseUrl + data.item.logo"
                        target="_blank"
                        class="cursor-pointer"
                        v-if="data.item.logo != null"
                    >
                        <b-img :src="baseUrl + data.item.logo" fluid class="mr-2 rounded" />
                    </b-link>
                    <b-link v-else></b-link>
                </template>

                <template #cell(balance_type)="data">
                    {{ data.item.balance_type_id ? data.item.balance_type.name : '' }}
                </template>

                <template #cell(game_category)="data">
                    {{ data.item.game_category_id ? data.item.game_category.name : '' }}
                </template>

                <template #cell(type)="data">
                    {{ data.item.type == 1 ? 'Payment' : 'Game' }}
                </template>

                <template #cell(is_new)="data">
                    {{ data.item.is_new == 1 ? 'Có' : 'Không' }}
                </template>

                <template #cell(created_at)="data">
                    {{ data.item.created_at ? formatDateTime(data.item.created_at).replace(".000000Z", "") : '' }}
                </template>

                <template #cell(updated_at)="data">
                    {{ data.item.updated_at ? formatDateTime(data.item.updated_at).replace(".000000Z", "") : '' }}
                </template>

                <!-- Column: Actions -->
                <template #cell(action)="data">
                    <b-button v-b-tooltip.hover.v-info :title="$t('Edit')" v-if="$can('update', 'thirdpartner')" variant="primary"
                        class="btn-icon btn-sm mr-50" @click="updateNewTitle(data.item)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </template>
            </b-table>
            <div class="p-2">
                <b-row>
                    <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              ">
                        <span class="text-muted">Showing {{ dataMeta.of }} entries</span>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <!-- Add adjustmentReason Modal -->
        <AddNewThirdPartnerModal @refetch-data="refetchData" :listBalanceType.sync="listBalanceType" :listGameCategory.sync="listGameCategory"/>

        <!-- Update adjustmentReason Modal -->
        <UpdateThirdPartnerModal @refetch-data="refetchData" :thirdPartnerDetail.sync="thirdPartnerDetail" :listBalanceType.sync="listBalanceType" :listGameCategory.sync="listGameCategory"/>

    </div>
</template>
  
<script>
import {
    BButton,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BImg,
    VBTooltip
} from "bootstrap-vue";
import i18n from "@/libs/i18n";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime, numberFormat } from "@core/utils/filter";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import flatPickr from "vue-flatpickr-component";
import settingStoreModule from "@/views/local-setting/setting/settingStoreModule";
import agentStoreModule from "@/views/agent/agentStoreModule";
import AddNewThirdPartnerModal from "@/views/local-setting/setting/third-partner/AddNewThirdPartnerModal.vue";
import UpdateThirdPartnerModal from "@/views/local-setting/setting/third-partner/UpdateThirdPartnerModal.vue";


export default {
    components: {
        AddNewThirdPartnerModal,
        UpdateThirdPartnerModal,
        flatPickr,
        BButton,
        BFormGroup,
        BModal,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BTable,
        BTr,
        BTh,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        BImg,
        VBTooltip
    },
    directives: {
        "b-modal": VBModal,
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            thirdPartnerDetail: null,
        };
    },
    methods: {
        addNewTitle() {
            this.$bvModal.show('modal-add-new-third-partner')
        },
        updateNewTitle(data) {
            this.thirdPartnerDetail = data;
            this.$bvModal.show('modal-update-third-partner')
        },
    },
    setup() {
        const AGENT_MODULE_NAME = "agent";
        if (!store.hasModule("setting"))
            store.registerModule("setting", settingStoreModule);
        if (!store.hasModule(AGENT_MODULE_NAME))
            store.registerModule(AGENT_MODULE_NAME, agentStoreModule);
        onUnmounted(() => {
            if (store.hasModule("setting")) store.unregisterModule("setting");
        });

        const codeProduct = ref([]);
        const perPage = ref(25);
        const totalParams = ref(0);
        const currentPage = ref();
        const sortBy = ref("id");
        const isSortDirDesc = ref(false);

        const refParamListTable = ref(null);
        const tableColumns = [
            { key: "index", label: "#", sortable: false },
            { key: "name", label: "Name", sortable: false },
            { key: "code", label: "Code", sortable: false },
            { key: "balance_type", label: "Balance type", sortable: false },
            { key: "type", label: "Type", sortable: false },
            { key: "game_category", label: "Game category", sortable: false },
            { key: "status", label: "Status", sortable: false },
            { key: "is_new", label: "Is new", sortable: false },
            { key: "start_maintenance", label: "Start maintenance", sortable: false },
            { key: "end_maintenance", label: "End maintenance", sortable: false },
            { key: "logo", label: "Logo", sortable: false },
            { key: "created_at", label: "Created at", sortable: false },
            { key: "updated_by", label: "Updated by", sortable: false },
            { key: "updated_at", label: "Updated at", sortable: false },
            { key: "action", label: i18n.t("Actions") },
        ];
        const listBalanceType = ref([]);
        const listGameCategory = ref([]);
        const refetchData = () => {
            refParamListTable.value.refresh();
            getListParams();
        };

        watch(
            [currentPage],
            () => {
                refetchData();
            }
        );
        const dataParamList = ref([]);
        const getListParams = (ctx, callback) => {
            store
                .dispatch("setting/fetchThirdPartner", {})
                .then((response) => {
                    perPage.value = response.pageSize;
                    dataParamList.value = response.data.data.map((item, index) => {
                        return {
                            ...item,
                            index: index + 1
                        }
                    });
                    totalParams.value = response.data.count;
                });
        };
        getListParams();
        const dataMeta = computed(() => {
            return {
                of: totalParams.value,
            };
        });

        store
            .dispatch("setting/fetchBalanceType", {})
            .then((response) => {
                listBalanceType.value = response.data.data;
            });

        store
            .dispatch("agent/fetchGameCategory", {})
            .then((response) => {
                listGameCategory.value = response.data.data;
            });

        const resolveStatus = status => {
            if (status == 1) return { label: 'Active', variant: 'primary' }
            if (status == 2) return { label: 'Inactive', variant: 'danger' }
            return { label: 'None', variant: 'secondary' }
        }

        return {
            tableColumns,
            perPage,
            currentPage,
            totalParams,
            dataMeta,
            sortBy,
            isSortDirDesc,
            refParamListTable,
            getListParams,
            refetchData,
            numberFormat,
            formatDateTime,
            baseUrl,
            dataParamList,
            resolveStatus,
            listBalanceType,
            listGameCategory
        };
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
  